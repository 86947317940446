.porsche-hero {
    margin: 30px 0;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.porsche-generateur {
    padding: 40px 0;
}

.title_holder {
    position: relative;
    min-height: 50vh;
    border: 1px var(--color-bg-secondary) solid;
    overflow: hidden;

    h3 {
        position: absolute;
        margin: 5px;
    }
}

#porsche-generateur-holder {
    border-radius: 12px;
    border-width: 2px;
    height: 100%;

    @media screen and (max-width: 650px) {
        min-height: 30vh;
        order: 2;
    }
}

#stringgenerator-button, #stringgenerator-button-clear {
    background: var(--color-bg-secondary);
    border: none;
    color: var(--color-secondary);
    padding: 10px;
    transition: .3s ease-out;
    border-radius: 8px;

    cursor: pointer;
}

#stringgenerator-button:hover {
    background: var(--color-yes);
}

#stringgenerator-button-clear:hover {
    background: var(--color-no);
}

.porsche-generateur-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
}

.porsche-generateur-js {
    display: flex;
    flex-direction: column;
    gap: 30px;
    grid-column: span 4;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 3;
    }

    @media screen and (max-width: 650px) {
        grid-column: span 6;
        order: 2;
    }
}

.porsche-generateur-card {
    grid-column: span 2;
    position: relative;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 3;
    }

    @media screen and (max-width: 650px) {
        grid-column: span 6;
    }
}

.generateur-card-picture {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background: var(--color-black);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30%;
        z-index: 10;
        border-radius: 10px;

        background: linear-gradient(180deg, var(--color-black) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: 10;
        border-radius: 10px;

        background: linear-gradient(0deg, var(--color-black) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.generateur-card-img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    position: relative;

    @media screen and (max-width: 650px) {
        max-height: 450px;
    }
}

.generateur-card-info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 60px);
    z-index: 15;

    .info-model {
        position: absolute;
        top: 15px;
        left: 25px;
        color: var(--color-white);

        margin: 0;
    }

    .info-text {
        position: absolute;
        bottom: 35px;
        left: 30px;
        width: 100%;

        .info-description {
            color: var(--color-white);
        }

        .info-link {
            background: var(--color-white);
            border: none;
            color: var(--color-black);
            margin-top: 20px;
            padding: 10px;
            transition: .3s ease-out;

            border-radius: 8px;
        }
    }
}

.info-model {
    font-family: 'Yellowtail', 'DM Mono', monospace;
    font-size: 3rem;
}
