/* ==========================================================================
   FONTS / SETTINGS
   ========================================================================== */
/// Font family
:root {
    --font-primary: 'DM Mono', monospace;
    --font-secondary: 'Fira Code', monospace;

    --font-size-content: 1.1rem;

    /// Grosseur des textes
    --font-size-h1: 2.5rem;
    --font-size-h2: 1.5rem;
    --font-size-h3: 1.875rem;
    --font-size-h4: 1.5rem;

    @media screen and (max-width: 1024px){
        --font-size-content: 1rem;

        --font-size-h1: 2rem;
        --font-size-h2: 1rem;
        --font-size-h3: 1.375rem;
        --font-size-h4: 1rem;
    }
}
