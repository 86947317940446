.section-contact {
    min-height: calc(100vh - 310px);
}

.contact__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}

.contact__img {
    max-width: 500px;
    width: 100%;
    aspect-ratio: 1/1;
    border: solid 3px var(--color-primary);
}
