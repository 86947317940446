.projects-section {
    a {
        height: 100%;
        width: 100%;
        display: block;
        text-transform: uppercase;

        &:hover .project-hover {
            background: var(--color-opacity);
            transition: ease .3s;
        }

        @media screen and (max-width: $breakpoint-sm) {
            .project-hover {
                background: var(--color-opacity);
                transition: ease .3s;
                color: var(--color-action);
            }
        }
    }

    p, h2 {
        text-transform: uppercase;
    }

    img {
        display: block;
    }

    .projects-grid article {
        height: 55vh;

        @media screen and (max-width: $breakpoint-sm) {
            height: 45vh;
        }

        @media screen and (max-width: 600px) {
            height: 30vh;
        }

        img {
            height: 55vh;
            width: 100%;
            object-fit: cover;

            @media screen and (max-width: $breakpoint-sm) {
                height: 45vh;
            }

            @media screen and (max-width: 600px) {
                height: 30vh;
            }
        }
    }
}

.projects-hero {
    height: 30vh;

    .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.project-color {
    position: relative;
    height: 100%;
    width: 100%;

    &.green {
        background: #6C7C59;
    }

    &.red {
        background: #AB273C;
    }

    &.darkblue {
        background: #191E28;
    }

    &.blue {
        background: #2274A5;
    }

    p:nth-child(1) {
        position: absolute;
        top: var(--pad);
        right: var(--pad);

        @media screen and (max-width: $breakpoint-sm) {
            display: none;
        }
    }

    p:nth-child(2) {
        position: absolute;
        bottom: var(--pad);
        right: var(--pad);

        @media screen and (max-width: $breakpoint-sm) {
            display: none;
        }
    }

    p:nth-child(3) {
        position: absolute;
        bottom: var(--pad);
        left: var(--pad);
    }

    p:nth-child(4) {
        position: absolute;
        top: var(--pad);
        left: var(--pad);
    }
}

.project-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 100%;

    h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        width: 100%;
        height: 100%;

        @media screen and (max-width: $breakpoint-md) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: $breakpoint-sm) {
            font-size: 1.5rem;
            font-weight: 500;
        }

        @media screen and (max-width: 500px) {
            padding-left: 10px;
            justify-content: flex-start;
            text-align: start;
            font-size: 1.25rem;
        }
    }

    p {
        position: absolute;
        top: 50%;
        transform: translate(-50%) rotate(-90deg);
        right: -120px;
        margin: 0;

        @media screen and (max-width: $breakpoint-md) {
            right: -110px;
        }

        @media screen and (max-width: $breakpoint-sm) {
            font-weight: 900;
        }

        @media screen and (max-width: 500px) {
            font-size: 0.875rem;
            right: -98px;
        }

        &.project-hover-contact{
            right: -100px;

            @media screen and (max-width: $breakpoint-md) {
                right: -90px;
            }

            @media screen and (max-width: 500px) {
                right: -82px;
            }
        }
    }
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
}

.projects-grid-span-1{
    position: relative;
    grid-column: span 1;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 3;
    }
}

.projects-grid-span-2{
    position: relative;
    grid-column: span 2;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 3;
    }
}

.projects-grid-span-3{
    position: relative;
    grid-column: span 3;
}

.projects-grid-span-3-to-6{
    position: relative;
    grid-column: span 3;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 6;
    }
}

.projects-grid-span-4{
    position: relative;
    grid-column: span 4;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 3;
    }
}

.projects-grid-span-5{
    position: relative;
    grid-column: span 5;

    @media screen and (max-width: $breakpoint-md) {
        grid-column: span 6;
    }
}

.projects-grid-span-6{
    position: relative;
    grid-column: span 6;
}
