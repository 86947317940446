.calc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: calc(100vh - 80px);
}

.calc__addition {
    display: flex;
    gap: 30px;

    margin-bottom: 30px;

    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
}

.calc_addtion__input {
    display: flex;
    flex-direction: column;
}

.calc-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 50px;

    button, input{
        border: 3px solid var(--color-primary);
        font-size: 1.5rem;
        color: var(--color-black);
        padding: 0;
    }
    
    button:hover {
        cursor: pointer;
    }

    .wrapper{
        display: flex;
        gap: 30px;

        @media screen and (max-width: 769px) {
            flex-direction: column;
        }
    }

    h1, h2{
        margin: 5px 0 30px 0;
        text-align: center;
        padding: 0 30px;
    }

    h2{
        text-decoration: underline;
    }
}

.calc-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calc__grid {
    display: grid;
    grid-template-columns: repeat(4, 80px);
    grid-template-rows: repeat(6, 80px);
    gap: 24px;

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(4, 65px);
        grid-template-rows: repeat(6, 65px);
        gap: 12px;
    }
}

.calc__result {
    grid-column: span 4;
    position: relative;

    &::after {
        content: "";
        color: var(--color-black);
        position: absolute;
        bottom: 0;
        left: 5%;
    }
}

.calc__result__input {
    width: 100%;
    height: 100%;

    text-align: right;

    &:focus-visible {
        outline: none;
    }
}

.calc__operation.calc__span {
    grid-row: span 2;
}

.calc__historique {
    border: 3px solid var(--color-bg-secondary);
}

#calc-history-list {
    padding: 5px;
}
