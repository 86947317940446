/* ==========================================================================
   HEADINGS / ELEMENT
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-primary);
    line-height: 1;
}

h1 {
    font-size: var(--font-size-h1);
    margin: 0;
    font-weight: 300;
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: 300;
}

h3 {
    font-size: var(--font-size-h3);
    font-weight: 500;
}

h4 {
    font-size: var(--font-size-h4);
    font-weight: 400;
}
