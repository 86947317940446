/* ==========================================================================
   RESET / GENERIC
   ========================================================================== */

// La propriété CSS box-sizing définit la façon dont la hauteur et la largeur totale d'un élément est calculée (avec le modèle de boîte CSS).
// Références: https://developer.mozilla.org/fr/docs/Web/CSS/box-sizing

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
}

