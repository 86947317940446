/* ==========================================================================
   header / component
   ========================================================================== */
.header {
    color: var(--color-bg-primary);
    background: var(--color-primary);
    position: relative;
    padding: 5px 0;

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    z-index: 9999;

    transition: all 0.4s $ease-out-expo;

    .header-is-hidden.is-scrolling-down & {
        transform: translateY(-100%) translateY(4px);
    }
}

.header__left {
    display: flex;
    align-items: center;
    gap: 4px;
}

.header__brand {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 9999;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;

    @media screen and (min-width: $breakpoint-md) {
        display: none;
    }
}

.nav-primary {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110vh;
    padding-top: 80px;
    display: flex;
    overflow-y: auto;
    z-index: 999;

    transition: all 0.4s $ease-out-expo;
    transform: translateY(-100%);

    @media screen and (min-width: $breakpoint-md) {
        width: auto;
        height: auto;
        transform: none;
        position: relative;
        padding: 0 10px;
        display: block;
        z-index: 999;
    }

    .nav-is-active & {
        transform: translateY(0);
        background-color: var(--color-primary);
    }

    ul {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        list-style: none;
        width: 100%;

        transition: all 0.4s 0.3s $ease-out-back;
        transform: translateY(-70%);
        opacity: 0;

        .nav-is-active & {
            transform: translateY(0);
            opacity: 1;
        }

        @media screen and (min-width: $breakpoint-md) {
            flex-direction: row;
            opacity: 1;
            transform: none;

            li {
                font-size: 32px;
            }
        }

        li {
            display: block;

            transition: all 0.3s ease-out;
            list-style-type: none;
            padding: 6px 30px;

            &:hover {
                color: var(--color-bg-primary);
            }
        }

        .menu-item a {
            font-size: 30px;
            padding: 20px 0;
            color: var(--color-bg-primary);

            display: block;
            text-align: center;
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .nav-primary ul {
        margin: 0;

        li {
            display: flex;
            align-items: center;
        }

        .menu-item a {
            font-size: 18px;
            padding: 0;

            transition: 0.5s all ease-out;

            &:hover {
                color: var(--color-action);
            }
        }
    }
}

.header__toggle {
    --bar-width: 32px;
    --bar-height: 6px;
    --bar-spacer: 4px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    padding: 0;
    position: relative;
    width: var(--bar-width);
    height: var(--bar-width);
    z-index: 9999;

    span {
        background: var(--color-bg-primary);
        display: block;
        height: var(--bar-height);
        width: var(--bar-width);
        position: absolute;
        top: calc(50% - var(--bar-height) / 2);
        left: calc(50% - var(--bar-width) / 2);

        transition: 0.3s all;

        &:nth-child(1) {
            border-radius: 6px 0 0 0;
            top: calc(
                50% - var(--bar-height) / 2 - var(--bar-height) -
                var(--bar-spacer)
            );
        }

        &:nth-child(3) {
            border-radius: 0 0 6px 0;
            top: calc(
                50% - var(--bar-height) / 2 + var(--bar-height) +
                var(--bar-spacer)
            );
        }
    }

    .nav-is-active & {
        span {
            border-radius: 6px;
            top: calc(50% - var(--bar-height) / 2);

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                transform: scaleX(0);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        display: none;
    }
}

.nav-is-active {
    body {
        overflow: hidden;
    }
}

// Ajout

.menu-item-special {
    #nav__primary__item-special {
        font-weight: 500;

        color: var(--color-action);
        border: solid 1px var(--color-action);
        border-radius: 2px;

        background: var(--color-neutral-secondary);

        padding: 4px 12px;

        &:hover {
            background: var(--color-action);
            color: var(--color-bg-primary);
            transition: ease-out 0.3s;
        }
    }
}

.menu-circle {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: none;
    border: none;

    lottie-player {
        position: absolute;
        z-index: 9999;
        height: 70px;
        width: 70px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            color: white !important;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.gemini::after {
        content: "AI";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
        color: var(--color-secondary);
    }
}

// Sidebar

:root {
    --stock-gap: 24px;
}

.sidebar {
    position: sticky;
    top: var(--stock-gap);
    left: var(--stock-gap);

    height: calc(100vh - (var(--stock-gap)*2));
    padding: var(--stock-gap);
    overflow-x: hidden;

    background: rgba(0,0,0,0.5);
    border-radius: var(--stock-gap);
    color: var(--color-white);

    ul {
        padding: 0;
    }
}

.sidebar__toggle {
    position: absolute;
    bottom: 10%;
    right: 0;
    //transform: translate(50%, -50%);
    padding: 10px;
    background: rgba(0,0,0,0.8);
    border-radius: var(--stock-gap);

    &:hover {
        cursor: pointer;
    }
}

.sidebar__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--stock-gap);
}

.sidebar__menu__separation {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.7);
    opacity: .8;
}

.sidebar__title {
    font-size: 0.8rem;
    margin: 0;
}

.sidebar__list {
    margin: 5px 0 0 0;
    width: 100%;

    li {
        position: relative;

        a {
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 1em;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: calc(var(--stock-gap) * -1);
            height: 100%;
            width: 4px;
            border-radius: 0 10px 10px 0;
            background: var(--color-white);
            transition: all ease-out .3s;
            overflow: hidden;

            opacity: 0;
        }

        &:hover::after {
            opacity: .7;
        }

        &.active::after {
            opacity: 1;
        }
    }
}

.sidebar__close {

    span {
        display: none;
    }

    .sidebar__menu__section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sidebar__list li {
        text-align: center;

        a {
            margin-right: 0;
        }
    }
}
