/* ==========================================================================
   modal / components
   ========================================================================== */
.modal {
    --modal-padding: 60px;

    @media screen and (max-width: $breakpoint-md) {
        --modal-padding: 45px;
    }

    @media screen and (max-width: $breakpoint-sm) {
        --modal-padding: 30px;
    }

    @include cover(fixed);

    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-100%);
    transition: opacity .8s $ease-out-expo .4s, transform 0s .8s;
    z-index: 20;

    .modal-is-active & {
        opacity: 1;
        transform: translateY(0);
        transition: opacity .8s $ease-out-expo, transform 0s;
    }
}

.modal__scrim {
    @include cover();

    background-color: var(--color-bg-primary);
    opacity: .8;

}

.modal__box {
    background-color: var(--color-bg-primary);
    border: 2px solid var(--color-bg-secondary);
    border-radius: 10px;
    color: var(--color-primary);
    width: 80vw;
    position: relative;
    height: 80vh;
    z-index: 1;
    transform: translateY(100%);
    transition: all .4s $ease-in-expo;

    .modal-is-active & {
        opacity: 1;
        transform: translateY(0);
        transition: all .4s $ease-out-expo .2s;
    }
}

.modal__content {
    height: 100%;
    overflow-y: auto;
    padding: var(--modal-padding);
}

.modal__flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal__close {
    --bar-width: 2px;
    --button-size: 40px;

    background: transparent;
    border: none;
    cursor: pointer;
    height: var(--button-size);
    outline: none;
    padding: 0;
    position: absolute;
    right: calc(var(--modal-padding) / 2);
    top: calc(var(--modal-padding) / 2);
    width: var(--button-size);

    &::before,
    &::after {
        background: var(--color-primary);
        bottom: 0;
        content: '';
        height: var(--bar-width);
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        position: absolute;
        transition: transform 0.3s $ease-out-expo;
        width: 80%;
    }

    &::before {
        transform: rotate(-45deg);
    }
    &::after {
        transform: rotate(45deg);
    }

    &:hover {
        opacity: 0.7;
        &::before {
            transform: rotate(0);
        }
        &::after {
            transform: rotate(0);
        }
    }
}

.modal-is-active {
    overflow: hidden;
}
