/* ==========================================================================
   BODY / ELEMENT
   Le body permet d'initialiser les propriétés générales de la page.
   Les éléments de la page vont hérités des propriétés inscrite dans body
   ========================================================================== */

body {
    background: var(--color-bg-primary);
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: var(--font-size-content);
    line-height: 1.6;
}

.nav-is-active body {
    -ms-overflow-style: none; //hide IE and Edge scrollbar
    scrollbar-width: none; //hide Firefox scrollbar

    ::-webkit-scrollbar {
        display: none; //hide Chrome, Safari and Opera scrollbar
    }
}

ul {
    list-style: none;
}

.section {
    margin: 80px 0;

    @media screen and (max-width: $breakpoint-md) {
        margin: 40px 0;
    }
}

#info {
    position: fixed;
    bottom: var(--pad);
    right: var(--pad);
    z-index: 14;

    cursor: pointer;
}

.d-flex {
    display: flex !important;
}

.d-block {
    display: block !important;
}

.d-none {
    display: none !important;
}

.position-relative {
    position: relative !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.text-center {
    text-align: center !important;
}

.text-underline {
    text-decoration: underline !important;
}

.footer {
    padding: 16px;

    p {
        text-align: center;
        margin: 0;
    }

    strong {
        font-weight: normal;
    }
}
