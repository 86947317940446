/* ==========================================================================
   DIMENSIONS / SETTINGS
   ========================================================================== */

// Valeurs utilisées pour les médias query
$breakpoint-xs: 375px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1440px;
$breakpoint-xl: 1920px;

:root {
    --spacing-xs: 5px;
    --spacing-sm: 15px;
    --spacing-md: 30px;
    --spacing-lg: 50px;
    --spacing-xl: 100px;


    --pad: max(20px, 4vmin);

    --vw: 10.78px;
    --vh: 12.61px;
    --vmax: 12.61px;
    --vmin: 10.78px;
}
