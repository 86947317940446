// FONTS
// ici sont chargé les fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

// TOOLS
// mixins et fonctions utilisés globalement dans le SCSS (Sass)
@import 'tools/cover';

// SETTINGS
// Contient les variables sur les polices, les définitions de couleurs, les définitions de  grandeurs,   etc.
@import 'settings/colors';
@import 'settings/easing';
@import 'settings/fonts';
@import 'settings/dimensions';

// GENERIC
// Permet de réinitialiser les CSS et de changer le modèle de boîte en box-sizing: border-box
@import 'generic/normalize';
@import 'generic/reset';

// ELEMENTS
// Modification des styles généraux de la page. Ce sont les styles qui seront généralement hérités par les éléments de la page.
@import 'elements/body';
@import 'elements/cookies';
@import 'elements/headings';
@import 'elements/icons';
@import 'elements/links';
@import 'elements/modal';
@import 'elements/wrapper';

// LIBRAIRIES AND OTHERS
// Swiper et icons

// SWIPER JS
@import '../../node_modules/swiper/swiper';
//@import '../../node_modules/swiper/modules/pagination/pagination.scss';
//@import '../../node_modules/swiper/modules/navigation/navigation.scss';

@import 'components/header';
@import 'components/gemini';

@import 'components/canvas';
@import 'components/theme-color';
@import 'components/weather';
@import 'components/projects';
@import 'components/contact';
@import 'components/time';
@import 'components/calc';
@import 'components/wizard';
@import 'components/spotify';
@import 'components/threejs';
@import 'components/bored-api';
@import 'components/porsche';
@import 'components/stocks';


// COMPONENTS
// Composants d'interface utilisateur spécifiques.
// C’est là que la majorité de notre travail a lieu.


// UTILITIES
// Utilitaires et classes auxiliaires avec possibilité de remplacer tout ce qui précède, par exemple masquer les éléments de la page
@import 'utilities/accessibility';
