/* ==========================================================================
   cover / tools
   ========================================================================== */

@mixin cover($position: absolute) {
    position: $position;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
