/* ==========================================================================
   LINKS / ELEMENTS
   Style de base de tous les liens et bouton du site
   ========================================================================== */

a {
    color: var(--color-primary);
    text-decoration: none;

    &:hover {
        color: var(--color-action);
    }
}

.button{
    padding: 16px 32px;
    display: inline-block;
    border-radius: 10px;
    font-weight: 500;
    font-family: var(--font-secondary);
    text-decoration: none;
    cursor: pointer;
}

.br-button{
    display: none;

    @media screen and (max-width: $breakpoint-sm) {
        display: block;
    }
}

