.spotify {
    position: relative;
}

.spotify__hero {
    display: flex;

    margin-bottom: 32px;

    @media screen and (max-width: 540px) {
        flex-direction: column;
        align-items: center;
    }
}

.spotify__playlist__txt {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 32px;

    @media screen and (max-width: 540px) {
        text-align: center;
        margin-left: 0;
    }
}

#spotify__playlist__img {
    aspect-ratio: 1/1;
    width: 200px;
    height: 200px;
}

.spotify__playlist__info {
    display: flex;
    align-items: center;
    gap: 32px;
}

.spotify__table {
    border-collapse: collapse;
    width: 100%;
}

.spotify__table__head {

    th {
        padding: 0 10px;
        text-align: left;
    }

    @media screen and (max-width: 768px) {
        th:nth-child(3) {
            display: none;
        }
    }

    @media screen and (max-width: 540px) {
        th:nth-child(4) {
            display: none;
        }
    }
}

.spotify__table__body {

    tr {
        border: solid 2px var(--color-primary);
    }

    td {
        padding: 10px;
    }

    @media screen and (max-width: 768px) {
        td:nth-child(3) {
            display: none;
        }
    }

    @media screen and (max-width: 540px) {
        td:nth-child(4) {
            display: none;
        }
    }

}

.embed-iframe {
    bottom: var(--pad);
    left: var(--pad);

    position: fixed;
    width: 30%;

    display: flex;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf;
    background: silver;

    @media screen and (max-width: 1200px) {
        width: 50%;
    }

    @media screen and (max-width: 800px) {
        width: 70%;
    }

    @media screen and (max-width: 540px) {
        width: calc(100% - var(--pad) * 2);
    }
}

.embed-iframe.open {
    padding: 40px 10px 10px 10px;
}

.embed-iframe.close {
    padding: 0;
    width: 0;
    height: 0;
}

.spotify__player {
    background: silver;
    height: 152px;
    width: 100%;
    border-radius: 12px;
}

.embed-iframe-close, .embed-iframe-minify {
    font-weight: 600;
    margin: 0;
    position: absolute;
    top: 10px;
    font-size: 20px;
    background: none;
    border: none;
    color: white;
    z-index: 15;

    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf;
    background: silver;

    &:hover {
        cursor: pointer;
    }
}

.embed-iframe-close {
    right: 10px;
}

.embed-iframe-minify {
    right: 40px;
}

.draggable-element-header {
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
    width: 100%;
    height: 40px;
    z-index: 10;
}

.spotify__play {
    cursor: pointer;
}

.spotify__play__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    cursor: pointer;
    transition: .3s ease-out;

    display: flex;
    align-items: center;
    justify-content: center;
}

.new__music__info:has(.spotify__play:hover, .spotify__play__hover:hover) .spotify__play__hover {
    opacity: 1;
}

.new__music__info {
    display: flex;
}

.new__music__img {
    position: relative;

    img {
        display: block;


        aspect-ratio: 1/1;
        height: 96px;
        width: 96px;
        min-width: 96px;
    }
}

.new__music__txt {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-left: 10px;
}

.new__music__txt p {
    margin: 0;
}
