.canvas__site_container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-bg-primary);
    color: var(--color-primary);
    transition-property: background-color;
    transition-duration: .9s;
    transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

.canvas__noise{
    position: fixed;
    z-index: 2;
    left: var(--pad);
    right: var(--pad);
    top: var(--pad);
    bottom: var(--pad);
    overflow: hidden;

    border: 1px solid var(--color-primary);
}

.canvas__canvas{
    position: absolute;
    opacity: 0.1; // adjust me!
    z-index: 3;
    pointer-events: none;
    user-select: none;
}

.canvas__text{
    position: absolute;
    z-index: 10;
    left: calc(var(--pad) * 2);
    right: calc(var(--pad) * 2);
    top: calc(var(--pad) * 2);
    bottom: calc(var(--pad) * 2);
}

.job_title{
    margin: 5px 0 5vh 3px;
}

.hover-element{
    position: absolute;
    right: calc(var(--pad) * 5);
    bottom: calc(var(--pad) * 5);
    padding: var(--pad);
    font-size: 32px;
    background: var(--color-bg-secondary);
    color: var(--color-action);
}

.accueil-list {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    height: 32px;

    font-family: var(--font-secondary);
    line-height: 1;
}
