#gemini-section {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 15;

    background: var(--color-gray-dark);
    height: calc(100svh - 80px);
    width: 100%;
    overflow: hidden;

    .wrapper {
        height: 100%;
        width: 100%;
    }
}

.wrapper-gemini {
    background: var(--color-black);
    border-radius: 16px;
    margin: 8px;
    padding: 48px 8px 8px 8px;
    height: calc(100% - 16px);
    width: calc(100% - 16px);

    .wrapper-gemini-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
        max-width: $breakpoint-md;
        margin: 0 auto;

        @media screen and (max-width: $breakpoint-md) {
            padding: 0 20px;
        }
    }
}

#gemini-open {
    cursor: pointer;
    position: relative;
    z-index: 9999;
}

#gemini-responses {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#gemini-question {
    display: flex;
    flex-direction: column;
}

#gemini-input {
    height: 50px;
    border-radius: 12px;
    padding-left: 12px;
    background: var(--color-gray-dark);
    border: none;
    color: var(--color-white);
    -webkit-box-shadow: 0px -10px 20px 4px var(--color-black);
    box-shadow: 0px -10px 20px 4px var(--color-black);

    &::placeholder {
        color: var(--color-white);
    }
}

.response-prompt {
    color: var(--color-gemini-primary);
    text-align: right;
}

.response-text {
    color: var(--color-white);
    margin: 10px 0;

    &:last-child {
        margin-bottom: 50px;
    }
}

.gemini-close {
    top: 16px;
    right: 16px;

    &::before, &::after {
        background: var(--color-white);
    }
}

.gemini-loading {
    position: absolute;
    bottom: 20%;
    right: 50%;
    transform: translateX(50%);

    height: 80px;
    width: 80px;
}

.gemini-icon {
    height: 70px;
    width: 70px;

    cursor: pointer;
}
