.cookies-bar {
    position: fixed;
    bottom: var(--pad);
    left: var(--pad);
    z-index: 99999999;
    padding: 10px;
    width: 320px;

    background: var(--color-bg-secondary);
    color: var(--color-secondary);
}

.cookies-container {
    border: 3px var(--color-bg-primary) dashed;
    padding: 10px;

    p {
        margin: 10px;
    }
}

.cookies-buttons {
    display: flex;
    justify-content: center;
    gap: 30px;

    button {
        background: var(--color-bg-secondary);
        border: 3px var(--color-bg-primary) solid;
        color: var(--color-secondary);
        padding: 10px;
        transition: .3s ease-out;
        cursor: pointer;
        text-transform: uppercase;

        &.js-cookie-consent-refuse:hover {
            background: var(--color-no);
        }

        &.js-cookie-consent-agree:hover {
            background: var(--color-yes);
        }
    }
}
