#weather-d{
    background: var(--color-d);
}

#weather-n{
    background: var(--color-n);
}

.weather-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition-property: background-color;
    transition-duration: .9s;
    transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

.weather-section{
    position: fixed;
    z-index: 2;
    left: var(--pad);
    right: var(--pad);
    top: var(--pad);
    bottom: var(--pad);
    overflow: hidden;

    border: 1px solid var(--color-primary);
}

.weather-background{
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
    }
}

.weather-card{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 20;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.weather-description{
    text-align: center;
}

.weather-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    label{
        margin-top: 30px;
    }

    input{
        margin-bottom: 20px;
    }
}

.city-icon{
    height: 100%;
    width: 100%;
}

.retour {
    position: relative;
    z-index: 10;

    a {
        display: inline-block;
        padding: 10px;
        background: var(--color-black);
        color: var(--color-white);
    }
}

#city-input {
    border: 3px solid var(--color-black);
    padding: 5px;
    text-align: center;
}

.weatherButton {
    background: var(--color-black);
    border: none;
    color: var(--color-white);
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
}

#info.weather-info {
    bottom: calc(var(--pad) + 10px);
    right: calc(var(--pad) + 10px);
}

.weather-modal {
    .modal__scrim {
        background-color: var(--color-black) !important;
    }

    .modal__box {
        background-color: var(--color-black) !important;
        border: 2px solid var(--color-white) !important;
        color: var(--color-white) !important;
    }

    .modal__close {
        &::before,
        &::after {
            background: var(--color-white) !important;
        }
    }
}
