/* ==========================================================================
   COLORS / SETTINGS
   ========================================================================== */

:root {
    // Couleur de base
    --color-white: #F2F2F2;
    --color-black: #0D0D0D;
    --color-gray-light: #A6A6A6;
    --color-gray-dark: #3e4245;

    --color-yes: #4CAF50;
    --color-no: #F44336;

    --color-d: #f9ac4d;
    --color-n: #6f65ca;

    --color-gemini-primary: #725AC1; //#8D86C9 //#725AC1
    --color-gemini-secondary: #ECA72C;


    .theme-light{
        // Couleurs du projet light

        --color-action: #C2E812;

        --color-primary: #0D0D0D;
        --color-secondary: #F2F2F2;

        --color-bg-primary: #F2F2F2;
        --color-bg-secondary: #0D0D0D;

        --color-opacity: rgba(0,0,0,.7);
    }

    .theme-dark{
        // Couleurs du projet light

        --color-action: #307351;

        --color-primary: #F2F2F2;
        --color-secondary: #0D0D0D;

        --color-bg-primary: #0D0D0D;
        --color-bg-secondary: #F2F2F2;

        --color-opacity: rgba(255,255,255,.7);
    }
}
