.theme-color{
    position: absolute;
    z-index: 11;
    right: var(--pad);
    bottom: var(--pad);

    display: flex;
    align-items: flex-end;
    justify-content: end;
}

.theme-button{
    background: var(--color-primary);
    color: var(--color-bg-primary);
    border: none;
    cursor: pointer;

    padding: 5px;
}

.theme-button:hover{
    background: var(--color-action);
    transition: background ease-out .3s;
}
