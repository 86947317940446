.wizard-section__theme{
    width: 100%;
    height: 30px;
    background: var(--color-primary);
    color: var(--color-bg-primary);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
}

.wizard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    height: 100vh;
    padding: 80px 0;

    input{
        border: solid 2px var(--color-primary);
    }
}

.wizard__start{

    h2{
        margin-top: 80px;
    }
}

.wizard__start__button{
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
}

#players{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#players input:last-child {
    margin-bottom: 15px;
}

.wizard__game{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
}

.wizard__game__table td{
    padding: 15px;
}

.wizard__game__dealer{
    display: flex;
}
