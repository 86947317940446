.time{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: calc(100vh - 162px);
    width: 100%;
}

#time{
    font-size: 32px;
    font-weight: Bold;
    margin: 0;
}
