.stocks__container {
    //padding: var(--stock-gap);

    //height: 100vh;
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url("../assets/bg/stocks-bg-3440.webp") fixed;
    background-size: cover;


    @media screen and (max-width: 1921px) {
        //background-image: url("../assets/bg/stocks-bg-1920.webp");
    }
}

.stocks-section {
    display: flex;
    gap: var(--stock-gap);
}

.stocks__sidebar {
    position: relative;
}

.stocks__info {
    width: 100%;
    margin: var(--stock-gap);
}

.stocks__cards {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    //grid-auto-rows: 150px;
    gap: var(--stock-gap);

    width: 100%;
}

.stocks-js {
    //position: relative;

    > div {
        margin-left: var(--stock-gap);
        margin-right: var(--stock-gap);

        &:first-child {
            margin-top: var(--stock-gap);
        }
    }

    p {
        margin: 0;
    }
}

.stocks__card {
    height: 100%;
    width: 100%;
    border-radius: var(--stock-gap);

    .stocks-js, &.ipinfo {
        background: rgba(0,0,0,0.5);
        border-radius: var(--stock-gap);
        color: var(--color-white);
    }

    &.swiper {
        grid-column: span 12;
    }

    &.ipinfo {
        grid-column: span 12;
        padding: var(--stock-gap);

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .ipinfo__table {

            th {
                text-align: left;
            }

            @media screen and (max-width: 600px) {

                tr {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        #ipinfo-map {
            border-radius: var(--stock-gap);
            height: 400px;
        }

        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;

            #ipinfo-map {
                margin-top: var(--stock-gap);
            }
        }
    }
}

.stocks__card__brand {
    display: flex;
}

.stocks__card__name {
    font-size: 0.8rem;
}

.stocks__card__symbol {
    font-size: 1.2rem;
}

.price__span {
    font-size: 1.8rem;
}

.stocks__card__price__split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .stocks__card__price__high {
        color: var(--color-yes);
    }
    .stocks__card__price__low {
        color: var(--color-no);
    }
}



// À voir si je le garde

.stocks__card__info {
    //margin-bottom: 90px;
}

.stocks__charts{
    //width: 100%;
}

.stocks__card__graph {
    display: none;
    /*
    position: absolute;
    bottom: -10px;
    left: -24px;
    width: 100%;
    margin-right: 20px;

    img {
        border-radius: var(--stock-gap);
    }
    */
}

.stocks__card__info {
    margin-bottom: var(--stock-gap);
}
