.bored {
    max-width: $breakpoint-sm;
    margin: 0 auto;

    height: calc(100vh - 162px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        text-align: center;
        margin-bottom: 50px;
    }
}

.bored-button-container {
    text-align: center;
}

#bored-button {
    background: var(--color-bg-secondary);
    border: none;
    color: var(--color-secondary);
    padding: 10px;

    cursor: pointer;
}

.bored-link {
    display: block;
    margin-top: 10px;
}
