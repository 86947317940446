/* ==========================================================================
   icons / component
   ========================================================================== */

[class^='icon'] {
    fill: currentColor; // inherit color of its context
    display: inline-block;
    flex: 0 0 auto; // prevent icon shrink/grow if in a flex parent
    height: 1em;
    vertical-align: middle;
    width: 1em;
    stroke: transparent;
}

.icon--stroke {
    fill: transparent;
    stroke: currentColor;
}

.icon--xs {
    height: 0.75em;
    width: 0.75em;
}

.icon--sm {
    height: 0.8em;
    width: 0.8em;
}

.icon--md {
    height: 1.25em;
    width: 1.25em;
}

.icon--lg {
    height: 1.75em;
    width: 1.75em;
}

.icon--xl {
    height: 7em;
    width: 7em;
}

.icon--logo{
    height: 40px;
    width: 26px;
    color: var(--color-bg-primary);
}

.icon--theme{
    height: 24px;
    width: 24px;
    color: var(--color-bg-primary);
}

.icon--play {
    height: 3em;
    width: 3em;
    color: var(--color-white);
}

.icon--info {
    height: 32px;
    width: 32px;
    color: var(--color-bg-secondary);
}

.icon-external-link {
    margin-left: 5px;
}

.icon--stocks {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    border-radius: calc(var(--stock-gap)/2);
}
